<template>
    <div class="expandable-image" :class="{ expanded: expanded}" @click="expanded = true">
        <i v-if="expanded" class="close-button">
            <svg style="width:33px;height:33px" viewBox="0 0 24 24">
                <path fill="#666666" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
        </i>

        <b-img v-bind="$attrs"></b-img>
    </div>
</template>

<script>
    export default {
        name: "ExpandImage",
        data () {
            return {
                expanded: false,
                closeButtonRef: null
            }
        },

        methods: {
            closeImage (e) {
                e.stopPropagation();
                this.expanded = false;
            },

            freezeVp (e) {
                e.preventDefault();
            }
        },

        watch: {
            expanded (status) {
                this.$nextTick(() => {
                    if (status) {
                        this.cloned = this.$el.cloneNode(true);
                        this.closeButtonRef = this.cloned.querySelector('.close-button');
                        this.closeButtonRef.addEventListener('click', this.closeImage);
                        this.cloned.addEventListener('click', this.closeImage);
                        document.addEventListener('keydown', e => {
                            if (e.key === 'Escape') {
                                this.closeImage(e);
                            }
                            document.removeEventListener('keydown', this);
                        });
                        document.body.appendChild(this.cloned);
                        document.body.style.overflow = 'hidden';
                        this.cloned.addEventListener('touchmove', this.freezeVp, false);
                        setTimeout(() => {
                            this.cloned.style.opacity = 1
                        }, 0);
                    } else {
                        this.cloned.style.opacity = 0
                        this.cloned.removeEventListener('touchmove', this.freezeVp, false);
                        setTimeout(() => {
                            this.closeButtonRef.removeEventListener('click', this.closeImage);
                            this.cloned.remove();
                            this.cloned = null;
                            this.closeButtonRef = null;
                            document.body.style.overflow = 'auto';
                        }, 250)
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .expandable-image {
/*        position: relative; */
        transition: 0.25s opacity;
        cursor: zoom-in;
    }

    body > .expandable-image.expanded {
        position: fixed;
        z-index: 999999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        display: flex;
        align-items: center;
        opacity: 0;
        padding-bottom: 0 !important;
        cursor: default;
    }

    body > .expandable-image.expanded > img {
        width: 100%;
        max-width: 1200px;
        max-height: 100%;
        object-fit: contain;
        margin: 0 auto;
    }

    body > .expandable-image.expanded > .close-button {
        display: block;
    }

    .close-button {
        position: fixed;
        top: 25px;
        right: 25px;
        display: none;
        cursor: pointer;
    }
    svg {
        filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));
    }
    svg path {
        fill: #FFF;
    }
    .expand-button {
        position: absolute;
        z-index: 999;
        right: 10px;
        top: 10px;
        padding: 0px;
        align-items: center;
        justify-content: center;
        padding: 3px;
        opacity: 0;
        transition: 0.2s opacity;
    }

    .expandable-image:hover .expand-button {
        opacity: 1;
    }
    .expand-button svg {
        width: 20px;
        height: 20px;
    }
    .expand-button path {
        fill: #FFF;
    }
</style>
