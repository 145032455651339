// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import VueMeta from 'vue-meta'
import VueMqtt from 'vue-mqtt'

Vue.config.productionTip    = false
Vue.prototype.$http         = Axios;

const token                 = store.state.token;
const _this                 = this;

if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.use(VueMqtt, process.env.VUE_APP_WS_URL, {
    clientId: 'WebClient-' + parseInt(Math.random() * 100000), 
    username: 'portal', 
    password: 'w1R6hMu3ir',
    keepalive: 10
});

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n'

Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
