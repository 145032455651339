<template>
    <div>
        <b-modal id="bv-modal-product-comment" size="lg">
            <template #modal-header>
                <h3>{{$t('form_comment.comments_on_product', { name: offer_name})}}</h3>
            </template>
            <template #modal-footer>
                    <b-col>
                        <b-input-group class="mt-3">
                            <b-form-input v-model="comment" maxlength="200" :disabled="!(rule_producer || rule_lineuper || rule_superproducer)"></b-form-input>
                            <b-input-group-append>
                                <b-button size="sm" variant="success" @click="saveComment()" :disabled="!(rule_producer || rule_lineuper || rule_superproducer)">{{$t('main.send')}}</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col class="text-right">
                        <b-button variant="danger" style="margin-left: 10px"  @click="$bvModal.hide('bv-modal-product-comment')">{{$t('main.close')}}</b-button>
                    </b-col>

            </template>
            <b-overlay :show="loading" rounded="sm">
                <table class="table">
                    <thead>
                        <tr>
                            <th>{{$t('main.date')}}</th>
                            <th>{{$t('main.role')}}</th>
                            <th>{{$t('main.author')}}</th>
                            <th>{{$t('main.message')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in data" :key="item.id">
                            <td>{{item.ctime}}</td>
                            <td>{{item.rule_name}}</td>
                            <td>{{item.user_name}}</td>
                            <td>{{item.message}}</td>
                        </tr>
                    </tbody>
                </table>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import Axios from 'axios';
    import bus from "../bus";

    export default {
        name: 'ProductComment',
        data: function () {
            return {
                data: {},
                offer_id: 0,
                offer_name: '',
                loading: false,
                comment: ''
            }
        },
        mounted() {
            bus.$on("show-offer-comment", (offer_id) => {
                if (offer_id != 0) this.loadOffer(offer_id);
                this.offer_id = offer_id;
            console.log('set offer_id = ', this.offer_id);
            });
        },

        methods: {
            loadOffer(offer_id){
                Axios
                        .get(this.prefix_url + '/api/ether?ts=' + Date.now()+'&type=load_offer&offer_id='+offer_id)
                        .then(response => {
                            this.data       = response.data.data;
                            this.offer_name = response.data.offer_name;
                            this.offer_id   = offer_id;
                            this.$root.$emit('bv::show::modal', 'bv-modal-product-comment');
                });
            },

            saveComment() {
                Axios
                        .post(this.prefix_url + '/api/ether?ts=' + Date.now(), {
                            method:     'save_full_comment',
                            offer_id:   this.offer_id,
                            comment:    this.comment
                        }).then(response => {
                            this.loadOffer(this.offer_id);

                });
            }
        },

        computed: {
            rule_producer: function (){return this.$store.getters.getRule('producer') },
            rule_lineuper: function (){return this.$store.getters.getRule('lineuper') },
            rule_admin: function (){return this.$store.getters.getRule('tv_admin') },
            rule_superproducer: function (){return this.$store.getters.getRule('supervisorproducer') },
            prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
        }
    }
</script>