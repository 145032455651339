<template>
    <div>

    </div>
</template>

<script>
import bus from "@/bus";

export default {
  name: 'Settings',
  data: function () {
    return {

    }
  },

  metaInfo() {
    return {
      title: this.$t('main.title')
    }
  },
  mounted() {
    bus.$emit('set-header', this.$t('main.title'));
  }
}
</script>