<script>
import bus from "@/bus";
import Axios from "axios";

export default {
  computed: {
    prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
  },
  data: function () {
    return {
      loading: true,
      isStacked: false,
      item_id: '',
      history_data: [],
      history_fields:
          // пока статичные поля
          [
            {
              key: 'id',
              label: '#',
            },
            {
              key: 'param',
              label: this.$t('orders.history.key'),
              sortable: true
            },

            {
              key: 'old',
              label: this.$t('orders.history.old_value'),
              sortable: false,

            },
            {
              key: 'new',
              label: this.$t('orders.history.new_value'),
              sortable: false,

            },
            {
              key: 'date',
              label: this.$t('orders.history.date'),
              sortable: true
            },
            {
              key: 'author',
              label: this.$t('orders.history.author'),
            },
          ],
    }
  },
  methods: {
    hideModalHistory() {
      this.$bvModal.hide('modal-history');
    },

    showHistory(order) {
      Axios
          .get(this.prefix_url + '/api/opp?ts=' + Date.now()+'&type=history&order=' + order)
          .then(response => {
            console.log(response);
            if (response.data.success) {
              this.history_data = response.data.data;
              this.loading = false;
            }
            else {
              bus.$emit('view-message', { message: (response.data.message || this.$t('main.processing_error')), variant: 'danger' });
              this.hideModalHistory();
            }
          }).catch(e => {
        bus.$emit('view-message', { message: this.$t('main.processing_error') + ' ' + e.message, variant: 'danger' })
        this.hideModalHistory();
      });

        // this.history_data = [
        //   {"id": "01", "param": "status", "old": "Возврат на доработку", "new": "Отгрузка", "date": "2024-03-05 16:05","author":"Danil"},
        //   {"id": "02", "param": "phone", "old": "+1111111111", "new": "+1999999999", "date": "2024-03-05 16:02","author":"Danil"},
        //   {"id": "03", "param": "phone", "old": "+1111111111", "new": "+1999999999", "date": "2024-03-08 16:02","author":"Dmitry"},
        //   {"id": "04", "param": "phone", "old": "+222222", "new": "+1999999999", "date": "2024-03-08 16:02","author":"Dmitry"},
        //   {"id": "05", "param": "address", "old": "Улица 1", "new": "Улица 2", "date": "2024-03-09 16:02","author":"Dmitry"},
        //   {"id": "06", "param": "phone", "old": "+1111111111", "new": "+4454366", "date": "2024-03-18 16:02","author":"Dmitry"},
        //   {"id": "07", "param": "phone", "old": "+1111111111", "new": "+5466545", "date": "2024-03-28 11:02","author":"Dmitry"},
        //   {"id": "08", "param": "phone", "old": "+111343411", "new": "+1999999999", "date": "2024-03-02 06:02","author":"Dmitry"},
        //
        //
        // ];
        // this.loading = false;
    },
  },
  mounted() {
    bus.$on('history::item_id',(order) => {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.showHistory(order);
    })
  },
  beforeDestroy() {
    bus.$off('history::item_id');
  }
}
</script>

<template>
  <div>
    <b-modal id="modal-history" hide-footer size="xl">
      <template #modal-title>
        {{ $t('orders.history.title')}}
      </template>
      <b-overlay :show="loading" spinner-small>
      <b-card style="overflow-y: scroll; height:75vh;">
        <b-table :stacked="isStacked" hover primary-key="id" :fields=history_fields :items="history_data">
<!--          <template #cell(#)="data">-->
<!--          {{ data.index + 1 }}-->
<!--        </template>-->
        </b-table>
      </b-card>
        <b-checkbox inline size="sm" @change="isStacked = !isStacked">Stacked</b-checkbox>
      </b-overlay>
    </b-modal>
  </div>
</template>

