<template>
  <div class="autocomplete">
    <input
        type="text"
        @input="loadStates"
        v-model="search"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"

        v-bind:class="{ 'is-invalid': (isError || error == -1), 'is-valid': (error == 1), 'form-control': true }"
    />
    <ul id="autocomplete-results"  v-show="results.length > 0" class="autocomplete-results3">
      <li class="loading" v-if="isLoading">
        Loading results...
      </li>
      <li v-else
          v-for="(result, i) in results"
          :key="i"
          @click="setResult(result)"
          class="autocomplete-result"
          :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result.city_name }}, {{result.area_name}}, {{result.region_name}}
      </li>
    </ul>
  </div>
</template>

<script>
import debounce from 'debounce';

export default {
  name: 'cityForm',
  props: {
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },

    region:{
      type: String,
      required: false,
      default: ''
    },

    city:{
      type: String,
      required: true,
      default: ''
    }
  },

  data() {
    return {
      results: [],
      search: '',
      isLoading: false,
      arrowCounter: -1,
      error: 0
    };
  },

  watch: {
    city(val){
        this.search = val;
        if (this.search.length == 0) this.error = 0;
    }
  },

  mounted() {
    this.search = this.city;
    document.addEventListener('click', this.handleClickOutside)
  },

  destroyed() {
    document.removeEventListener('click', this.handleClickOutside)
  },

  methods: {
    loadStates: function () {
      this.isLoading = true;
      debounce((function (_this, event) {
        _this.$emit('setValue', event.target.value);
        if (event.target.value.length > 0) _this.error = -1;else _this.error = 0;
        if (event.target.value.length > 2) {
          _this.$http
              .get(_this.prefix_url + '/api/cities?query='+event.target.value+'&region='+ _this.region)
              .then(response => {
                _this.results = response.data.cities;
                _this.isLoading = false;
              })
        }else {
          _this.isLoading = false;
          _this.results = [];
        }
      })(this, event), 200)
    },

    setResult(result) {
      this.error = 1;
      this.$emit('result', result);
      this.search = result.city_name;
      this.results = [];
    },

    onChange() {
      this.$emit('input', this.search);
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.results = [];
        this.arrowCounter = -1;
      }
    },

    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },

    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },

    onEnter() {
      this.search = this.results[this.arrowCounter];
      this.results = [];
      this.arrowCounter = -1;
    },
  },

  computed : {
    prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
  }
}
</script>

<style>
.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results3 {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  z-index: 99;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4AAE9B;
  color: white;
}
</style>
