<template>
 <div>
    <div class="container col-xl-10 col-xxl-8 px-4 py-5">
        <div class="row align-items-center g-lg-5 py-5">
            <div class="col-md-10 mx-auto col-lg-5">
                <form class="p-4 p-md-5 border rounded-3 bg-light" @submit.prevent="login">
                    <div class="form-floating mb-3">
                        <label for="floatingInput">{{ $t("login.login") }}:</label>
                        <input type="email" v-model="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                    </div>
                    <div class="form-floating mb-3">
                        <label for="floatingPassword">{{ $t("login.password") }}:</label>
                        <input type="password" v-model="password" class="form-control" id="floatingPassword" placeholder="Password">
                    </div>
                    <button class="w-100 btn btn-lg btn-primary" type="submit">{{ $t('login.auth') }}</button>
                    <hr class="my-4">
                    <!--small class="text-muted">By clicking Sign up, you agree to the terms of use.</small-->
                </form>
            </div>
        </div>
    </div>
 </div>
</template>

<script>
import bus from "@/bus";

export default {
    data(){
        return {
            email : "",
            password : ""
        }
    },
    metaInfo() {
      return {
        title: this.$t('login.title')
      }
    },
    mounted() {
      bus.$emit('set-header', this.$t('login.title'));
    },
    methods: {
        login: function () {
            let email = this.email 
            let password = this.password
            this.$store.dispatch('login', { email, password })
                .then(() => this.$router.push('/'))
                .catch(err => {
                bus.$emit('view-message', { message: this.$t('main.authorisation_error'), variant: 'danger' });
            })
        }
    }
}
</script>
