<template>
    <div>
	<br/>
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <b-form inline>
                                    <router-link v-if="rule_order_hopshop" type="button" style="padding-left: 10px" class="btn btn-success" to="/orders/hopshop">{{ $t('orders.nav.new_order_hopshop')}}</router-link>&nbsp;
                                    <router-link v-if="rule_order_lots" type="button" class="btn" style="background-color: #76367e; padding-left: 10px;color: white" to="/orders/lots">{{ $t('orders.nav.new_order_broadcast')}}</router-link>
                                    <router-link v-if="rule_order_search" type="button" class="btn btn-success" style="margin-left: 10px" to="/orders/search">{{ $t('orders.nav.search_for_orders')}}</router-link>
                                  <b-button v-if="rule_order_create_ticket" variant="danger" style="margin-left: 10px" @click="$bvModal.show('bv-modal-create-ticket')">{{ $t('orders.nav.create_ticket')}}</b-button>
                                    <b-form-select v-model="filter" :options="so.option" style="margin-left: 20px" @change="changeFilter()"></b-form-select>
                                    <b-button variant="info" @click="loadData()" style="margin-left: 10px"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></b-button>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <br/>
      <div class="row">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('main.number_order')}}</th>
            <th scope="col">{{ $t('main.date_order')}}</th>
            <th scope="col">{{ $t('main.customer')}}</th>
            <th scope="col">{{ $t('main.phone')}}</th>
            <th scope="col">{{ $t('main.status_order')}}</th>
            <th scope="col">{{$t('main.operations')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in orders" :key="item.id">
            <td>{{index + 1}}</td>
            <td> {{item.number}}</td>
            <td>{{item.ctime}}</td>
            <td>{{item.client}}</td>
            <td>{{item.phone}}</td>
            <td>{{item.status}}</td>
              <td>
                  <button class="btn btn-sm btn-success"  @click="showOrder(item)" :disabled="!item.edit">
                      <b-icon-pencil-square></b-icon-pencil-square>
                  </button>
                <button class="btn btn-sm btn-info ml-1"  @click="showModalHistory(item.etherid+'-'+item.id)">
                  <b-icon-clock-history></b-icon-clock-history>
                </button>
              </td>
          </tr>
          </tbody>
        </table>
      </div>
      <b-modal id="bv-modal-create-ticket" size="lg">
        <template #modal-title>
          {{ $t('orders.modal.create_ticket')}}
        </template>
        <template #modal-footer>
          <b-button style="margin-left: 10px" class="mt-3 btn btn-success" @click="sendTicket" :disabled="data.customer_phone==='' || data.type_ticket==='' || data.comment_ticket===''">{{ $t('main.save')}}</b-button>
          <b-button style="margin-left: 10px" class="mt-3" @click="$bvModal.hide('bv-modal-create-ticket')">{{ $t('main.close')}}</b-button>
        </template>
        <b-row>
          <b-col>{{ $t('orders.modal.phone')}}</b-col>
          <b-col>
            <div class="input-group">
              <input type="phone" class="form-control" v-model="data.customer_phone" autocomplete="off" ref="orders_phone">
              <div class="input-group-append" >
                <button v-if="current_phone.length > 0" class="btn btn-success " type="button" id="button-addon2" @click="data.customer_phone = current_phone;current_phone = ''">{{current_phone}}</button>
              </div>
            </div>
            <!--b-form-input v-model="data.clientPhone" placeholder="Введите номер телефона"></b-form-input-->
          </b-col>
        </b-row>
        <br/>
        <b-row>
          <b-col>{{ $t('orders.modal.type_ticket')}}</b-col>
          <b-col><b-form-select v-model="data.type_ticket" :options="so.options_ticket" ref="order_type_ticket"></b-form-select></b-col>
        </b-row>
        <br/>
        <b-row>
          <b-col>{{ $t('orders.modal.comment')}}</b-col>
          <b-col>
            <b-form-textarea
                v-model="data.comment_ticket"
                rows="3"
                max-rows="6"
            >
            </b-form-textarea>
          </b-col>
        </b-row>
      </b-modal>
        <Order
                :bus="localbus"
                @close="loadData()"
         ></Order>
      <History></History>
    </div>
</template>

<script>
    import Vue from 'vue';
import Axios from 'axios';
import bus from "@/bus";
import Order from "@/components/Order.vue";
import History from "@/components/History.vue"

export default {
  name: 'Orders',

  components: {
        Order, History
  },

  data() {
        return {
            orders: [],
            filter: 'return',
            data: {
                customer_phone: '',
                type_ticket: '',
                comment_ticket: ''
            },
            so: {
                option: [
                    {value: 'return', text: this.$t('orders.variable.return_for_revision') },
                    {value: 'all', text: this.$t('orders.variable.all_orders') }
                ],
              options_ticket: [
                {value: '', text: this.$t('orders.modal.select_the_type_of_ticket'), disabled: true},
                {value: 'product', text: this.$t('orders.modal.complaint_about_the_product') },
                {value: 'delivery', text: this.$t('orders.modal.delivery_complaint') }
              ],
            },
            localbus: new Vue()
        }
    },

  metaInfo() {
    return {
        title: this.$t('orders.title')
    }
  },

  mounted() {
    this.loadData();
    bus.$emit('set-header', this.$t('orders.title'));
  },
  methods: {
    loadData() {                  // загрузка данных с backend
      Axios
          .get(this.prefix_url + '/api/order?ts=' + Date.now()+'&filter='+this.filter)
          .then(response => {
            console.log(response);
            this.orders = response.data.orders;
          });
    },

    showOrder(data) {
        this.localbus.$emit('show-order', {ether_id: data.etherid, order_id: data.id});
    },

    changeFilter(){
        this.loadData();
    },

    resetTicketForm(){
      this.data.customer_phone = '';
      this.data.type_ticket = '';
      this.data.comment_ticket = '';
    },


    showModalHistory(item,type) {
      bus.$emit('history::item_id',item,type)
      this.$bvModal.show('modal-history')
    },


    sendTicket(){
        if (this.data.customer_phone === '') {
            bus.$emit('view-message', { message: 'Введите номер телефона клиента', variant: 'danger' });
            this.focusRef(this.$refs['orders_phone']);
            return;
        }

        if (!this.ValidPhone(this.data.customer_phone)) {
            bus.$emit('view-message', { message: 'Не верный формат номера телефона клиента', variant: 'danger' });
            this.focusRef(this.$refs['orders_phone']);
            return;
        }
        if (this.data.type_ticket === '') {
            bus.$emit('view-message', { message: 'Выберете тип жалобы', variant: 'danger' });
            this.focusRef(this.$refs['orders_type_ticket']);
            return;
        }
// send_data
        Axios
           .post(this.prefix_url + '/api/helpdesk?ts=' + Date.now(), {
                    method: 'save',
                    data:   this.data
           }).then(res => {
                if (res.data.success) {
                    this.resetTicketForm();
                    bus.$emit('view-message', {message: 'Тикет ' + res.data.number + ' успешно создан.', variant: 'success'});
                }else{
                    bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
                }
        });
        this.$bvModal.hide('bv-modal-create-ticket');
    },

ValidPhone(phone) {
    let str = phone.replace(/\D/g, '');
    var re = /^\d{9}$/;
    if (typeof process.env.VUE_APP_COUNTRY === 'undefined' || process.env.VUE_APP_COUNTRY == 'uz' || process.env.VUE_APP_COUNTRY == 'pl') re = /^\d{9}$/;
    else if (process.env.VUE_APP_COUNTRY == 'kz') re = /^\+?7?\d{10}$/;
    else re = /^\+?7?\d{10}$/;

    return re.test(phone);
},

    focusRef(ref) {
        this.$nextTick(() => {
            this.$nextTick(() => {
                ;(ref.$el || ref).focus()
            })
        })
    },
  },

  computed : {
    rule_order_hopshop: function (){return this.$store.getters.getRule('order') },
    rule_order_zargar: function (){return this.$store.getters.getRule('orderzargar') },
    rule_order_lots: function (){return this.$store.getters.getRule('orderlots') },
    rule_order_search: function (){return this.$store.getters.getRule('orderSearch') },
    rule_order_create_ticket: function (){return this.$store.getters.getRule('ticketCreate') },

    current_phone: {
          get () { return this.$store.getters.getCurrentPhone; },
          set(value) { this.$store.dispatch('setCurrentPhone', value); }
    },
    prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
  }
}
</script>