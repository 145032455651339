<template>
    <div>
	<nav class="navbar navbar-expand-lg navbar-light bg-light">
    	    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        	<span class="navbar-toggler-icon"></span>
    	    </button>
    	    <h2 class="navbar-text">
        	{{ header }}
    	    </h2>
    	    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        	<ul class="navbar-nav mr-auto"></ul>
        	<b-form class="form-inline my-2 my-lg-0">
                <template v-if="vmix_status">
                    <b-button variant="success" size="sm" v-b-tooltip.hover :title="$t('nav.vmix_available')" v-if="isLoggedIn">
                        <b-icon-tv></b-icon-tv>
                    </b-button>
                </template>
                <template v-else>
                    <b-button variant="danger" size="sm" v-b-tooltip.hover :title="$t('nav.vmix_notavailable')" v-if="isLoggedIn">
                        <b-icon-tv></b-icon-tv>
                    </b-button>
                </template>
                <template v-if="mqtt_status">
                    <b-button variant="success" style="margin-left: 5px" size="sm" v-b-tooltip.hover :title="$t('nav.mqtt_available')" v-if="isLoggedIn">
                        <b-icon-reception-4></b-icon-reception-4>
                    </b-button>
                </template>
                <template v-else>
                    <b-button variant="danger" style="margin-left: 5px" size="sm" v-b-tooltip.hover :title="$t('nav.mqtt_notavailable')" v-if="isLoggedIn">
                        <b-icon-reception-1></b-icon-reception-1>
                    </b-button>
                </template>

                <b-button v-if="needUpdate && isLoggedIn" style="margin-left: 5px" class="btn btn-success" @click="updateMyApp()" size="sm">
                    {{ $t('nav.update_app') }}
                </b-button>
                <template v-if="rule_tv_admin">
                    <b-form-select style="margin-left: 5px" v-model="locale" :options="so.options_locale"></b-form-select>
                </template>
                <template v-else>
                    <template v-if="rule_order_hopshop || rule_order_zargar || rule_order_lots">
                        <b-form-select style="margin-left: 5px" v-model="locale" :options="so.options_locale_operator"></b-form-select>
                    </template>
                </template>
                <b-button v-if="isLoggedIn" style="margin-left: 5px" class="btn btn-danger" @click="logout" size="sm">
                    {{ $t('nav.logout')}} [{{userName}}]
                </b-button>
        	</b-form>
    	    </div>
	</nav>
	<div id="app">
	    <div class="container-fluid">
    		<div id="admin">
            	    <ul v-if="isLoggedIn" class="nav nav-tabs">
                        <li v-if="rule_order" class="nav-item">
                                <router-link class="nav-link" to="/orders/" exact>{{ $t('nav.orders')}}</router-link>
                        </li>
                        <li v-if="rule_customers" class="nav-item">
                            <router-link class="nav-link" to="/customers" exact>{{ $t('main.customers')}}</router-link>
                        </li>
                        <li v-if="rule_producer" class="nav-item">
                                <router-link class="nav-link" to="/tv">{{ $t('nav.producer_panel') }}</router-link>
                        </li>
                        <li v-if="rule_lineuper" class="nav-item">
                            <router-link class="nav-link" to="/ethers">{{ $t('nav.broadcast_planning')}}</router-link>
                        </li>
                        <li v-if="rule_lot_hopshop" class="nav-item">
                            <router-link class="nav-link" to="/hopshop">{{ $t('nav.hopshop_panel')}}</router-link>
                        </li>
                        <li v-if="rule_lot_hopshop" class="nav-item">
                            <router-link class="nav-link" to="/hopshop2">{{ $t('nav.hopshop_panel_new')}}</router-link>
                        </li>
                        <li v-if="rule_tv && active_ether != 0" class="nav-item">
                            <router-link class="nav-link" :to="'/tv/' + active_ether">{{ $t('nav.current_broadcast')}}</router-link>
                        </li>
                        <li v-if="rule_monitor" class="nav-item">
                            <router-link class="nav-link" to="/monitor">{{ $t('nav.broadcast_monitor')}}</router-link>
                        </li>
                        <li v-if="rule_stream" class="nav-item">
                            <router-link class="nav-link" to="/stream">{{ $t('nav.zargar_live_broadcast')}}</router-link>
                        </li>
                        <li v-if="rule_opp" class="nav-item">
                            <router-link class="nav-link" to="/opp">{{ $t('nav.opp_panel')}}</router-link>
                        </li>
                        <li v-if="rule_product_ticket" class="nav-item">
                            <router-link class="nav-link" to="/ticket/product">{{ $t('nav.tickets_for_complaints')}}</router-link>
                        </li>
                        <li v-if="rule_delivery_ticket" class="nav-item">
                            <router-link class="nav-link" to="/ticket/delivery">{{ $t('nav.delivery_tickets')}}</router-link>
                        </li>
                        <li v-if="rule_manifest" class="nav-item">
                            <router-link class="nav-link" to="/manifest">{{ $t('nav.manifests')}}</router-link>
                        </li>
                        <li v-if="rule_checkregistry" class="nav-item">
                            <router-link class="nav-link" to="/checkregistry">{{ $t('nav.registries_of_check')}}</router-link>
                        </li>
                        <li v-if="rule_settings" class="nav-item">
                                <router-link class="nav-link" to="/settings">{{ $t('nav.settings')}}</router-link>
                        </li>
            	    </ul>
            	    <div class="tab-content">
                        <router-view></router-view>
            	    </div>
    		</div>
    	    </div>
    	</div>
    </div>
</template>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        padding: 20px;
    }

    .nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    .nav .router-link-exact-active {
        color: #42b983;
    }

    h1 {
        padding-top: 10px;
        font-size: 2rem;
        text-align: center;
    }

    h2  {
        font-size: 1.5rem;
    }
</style>

<script>
  import bus from "./bus";
  import Axios from 'axios';

  export default {
    data: function () {
      return {
        header:         '',
        needUpdate:     false,
        vmix_status:    false,
        active_ether:   0,
        timer:          null,
        mqtt_status:    false,
          so:{
              options_locale: [
                  { 'value': 'en_US', 'text': 'English'},
                  { 'value': 'uz_Latn_UZ', 'text': 'Оʻzbek tili'},
                  { 'value': 'pl_PL', 'text': 'Język polski'},
                  { 'value': 'ru_RU', text: 'Русский' }
              ],
              options_locale_operator: [
                  { 'value': 'uz_Latn_UZ', 'text': 'Оʻzbek tili'},
                  { 'value': 'ru_RU', text: 'Русский' }
              ]
          }
      }
    },


    computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn },
      userName: function(){ return this.$store.getters.getUserName },
      userId: function(){ return this.$store.getters.getUserId },
      status: function(){ return this.$store.getters.authStatus },
      locale: function(){ return this.$store.getters.getLocale },

      rule_order_hopshop: function (){return this.$store.getters.getRule('order') },
      rule_order_zargar: function (){return this.$store.getters.getRule('orderzargar') },
      rule_order_lots: function (){return this.$store.getters.getRule('orderlots') },

      rule_tv_admin: function (){return this.$store.getters.getRule('tv_admin') },
      rule_order: function (){return this.$store.getters.getRule('orders') },
      rule_producer: function (){return this.$store.getters.getRule('producer') },
      rule_lineuper: function (){return this.$store.getters.getRule('lineuper') },
      rule_tv: function (){return this.$store.getters.getRule('tv') },
      rule_lot_hopshop: function (){return this.$store.getters.getRule('lot_hopshop') },
      rule_settings: function (){return this.$store.getters.getRule('settings') },
      rule_monitor: function (){return this.$store.getters.getRule('monitor') },
      rule_stream: function (){return this.$store.getters.getRule('stream') },
      rule_manifest: function (){return this.$store.getters.getRule('manifest') },
      rule_checkregistry: function (){return this.$store.getters.getRule('checkregistry') },
      rule_product_ticket: function (){return this.$store.getters.getRule('productticket') },
      rule_delivery_ticket: function (){return this.$store.getters.getRule('deliveryticket') },
      rule_opp: function (){return this.$store.getters.getRule('opp') },
      rule_customers: function (){return this.$store.getters.getRule('customers') },
      version: function () { return process.env.VERSION },
      prefix_url:function () { return process.env.VUE_APP_PREFIX_URL },
      tz: function (){ return this.$store.getters.getTZ },
      locale: {
            get () {
                let locale = this.$store.getters.getLocale;
                if (locale !== this.$i18n.locale)  this.$i18n.locale = locale;

                return locale;
            },
            set(value) {
                this.$i18n.locale = value;
                this.$store.dispatch('setLocale', value);
                this.change_locale(value);
          }
        },
    },

    created: function () {
      var _this = this;

      this.$http.interceptors.response.use(function (response){
          console.log('RP:', response.data);

          if (typeof response.data.needUpdate !== undefined) {
              _this.needUpdate = response.data.needUpdate;
          }

          if (typeof response.data.vmix_status !== undefined) {
              _this.vmix_status = response.data.vmix_status;
          }

          if (typeof response.data.locale !== undefined) {
              _this.$i18n.locale = response.data.locale;
          }
          return response;
      }, function (err) {
        return new Promise(function (resolve, reject) {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            _this.$store.dispatch("logout").then(() => {
              _this.$router.push('/login').catch(()=>{});
            })
          }
          throw err;
        });
      });

      this.$http.interceptors.request.use(function (request){
          console.log('RQ:', request);
          request.headers.Version = _this.version;
          request.headers.TZ      = _this.tz;
          return request;
        }, function (error) {
          return Promise.reject(error);
      });

      if (!_this.timer) {
          this.timer = setInterval(() => {
            this.setActiveEther();
          }, 60000);
          this.setActiveEther();
      }

      console.log('Start HopSHop Portal ['+this.version+'].\n');
    },

    mounted: function (){
        bus.$on("set-header", (message) => this.header = message);
        bus.$on("view-message", (data) => {
            this.makeToast(data.message, data.variant);
        });

        if (this.isLoggedIn) {
            this.subscribe_mqtt();
        }
    },

  mqtt: {
      'portal/#' (data) {
          let buffer = '';
          if (String.fromCharCode(data[0]) == 'C') {                                            // Call - начало звонка
              for (var i = 1; i < data.length; i++) {
                  buffer += String.fromCharCode(data[i]);
              }
              if (process.env.NODE_ENV !== 'production') console.log(buffer);
              if (buffer.length > 0) {
                  this.makeToast(this.$t('main.start_call', {'phone': buffer}), 'info');
                  this.$store.dispatch('setCurrentPhone', buffer);
              }
          } else if (String.fromCharCode(data[0]) == 'T') {                                     // Change Ticket - изменены тикеты
              for (var i = 1; i < data.length; i++) {
                  buffer+=String.fromCharCode(data[i]);
              }
              if (process.env.NODE_ENV !== 'production') console.log(buffer);
              if (buffer.length > 0) {
                  this.$store.dispatch('setAcceptTicket', buffer);
              }
          } else if (String.fromCharCode(data[0]) == 'O') {                                     // Overdue - просрочено
              for (var i = 1; i < data.length; i++) {
                  buffer+=String.fromCharCode(data[i]);
              }
              if (process.env.NODE_ENV !== 'production') console.log(buffer);
              if (buffer.length > 0) {
                  let t = buffer.split(":");
                  this.makeToast('Обращение '+t[0]+' просрочено', 'info', t[1]);
              }
          }else if (String.fromCharCode(data[0]) == 'H') {                                      // Hangup - окончание звонка
              for (var i = 1; i < data.length; i++) {
                  buffer+=String.fromCharCode(data[i]);
              }
              if (buffer.length > 0) {
                  this.makeToast(this.$t('main.finish_call', { 'phone': buffer }), 'info');
              }
          }

      }
  },

    methods: {
        logout: function () {
            this.$store.dispatch('logout')
            .then(() => {
                this.$router.push('/login')
            });
        },

		makeToast(message, variant = null, url = null) {
      		let obj = {
                title: this.$t('main.new_message'),
                variant: variant,
                solid: true,
                autoHideDelay: 1000
            };
            if (url) obj.href = url;
            this.$bvToast.toast(message, obj);
    	},

        updateMyApp() {
            window.location.reload();
        },

        subscribe_mqtt(){
            let topic = process.env.VUE_APP_MQTT_TOPIC + '/uid_' + this.$store.getters.getUserId;
            if (process.env.NODE_ENV !== 'production') console.log('Subscribe topic: '+ topic);
            this.$mqtt.subscribe(topic);

            this.$mqtt.on('connect', () => {
              if (process.env.NODE_ENV !== 'production') console.log('MQTT: Connection succeeded!');
                this.mqtt_status = true;
            });

            this.$mqtt.on('reconnect', () => {
              if (process.env.NODE_ENV !== 'production') console.log('MQTT: Reconnection succeeded!');
                this.mqtt_status = true;
            });

            this.$mqtt.on('offline', () => {
              if (process.env.NODE_ENV !== 'production') console.log('MQTT: Offline');
                this.mqtt_status = false;
            });

            this.$mqtt.on('error', () => {
              if (process.env.NODE_ENV !== 'production') console.log('MQTT: Error');
                this.mqtt_status = false;
            });

            this.$mqtt.on('end', () => {
              if (process.env.NODE_ENV !== 'production') console.log('MQTT: End');
                this.mqtt_status = false;
            });

            this.$mqtt.on('disconnect', () => {
              if (process.env.NODE_ENV !== 'production') console.log('MQTT: Disconnect');
                this.mqtt_status = false;
            });

            this.$mqtt.on('close', () => {
              if (process.env.NODE_ENV !== 'production') console.log('MQTT: Close');
            });
        },

        change_locale(loc){
            Axios
              .post(this.prefix_url + '/api/users/'+this.userId, {
                  method: 'CHANGE_LOCALE',
                  locale: loc
              }).then(res => {
                if (res.data.success) {
                } else {
                    bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
                }
            }).catch(error => { if (process.env.NODE_ENV !== 'production') console.log(error)});
        },

        setActiveEther(){
            let _this = this;

            if (!this.isLoggedIn) return;

            Axios
                .get(_this.prefix_url+'/api/ethers?ts='+Date.now()+'&active=yes&tz='+Intl.DateTimeFormat().resolvedOptions().timeZone)
                .then(response => {
                    if (response.data.ethers.length ==0 ) _this.active_ether = 0;
                    else if (response.data.ethers.length == 1) _this.active_ether = parseInt(response.data.ethers[0].id);

            });
        }

    },
    watch: {
        isLoggedIn: function (val) {
            if (val) this.subscribe_mqtt();
        }
    }
  }
</script>

